import { useEffect, useState } from "react";
import { listenToNumberOfCompletedEvents } from "@michaelprichardson/cloudpresent-core";

export default function useOnlineUsers() {
  const [numberOfEvents, setNumberOfEvents] = useState(0);
  const [numberOfCompletedEvents, setNumberOfCompletedEvents] = useState(0);

  useEffect(() => {
    const unsubscribe = listenToNumberOfCompletedEvents((events, completed) => {
      setNumberOfEvents(events);
      setNumberOfCompletedEvents(completed);
    });

    return () => {
      unsubscribe();
    };
  }, [setNumberOfEvents, setNumberOfCompletedEvents]);

  return { numberOfEvents, numberOfCompletedEvents };
}
