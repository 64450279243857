import { useEffect, useState } from "react";
import { listenToCurrentOnlineUsers } from "@michaelprichardson/cloudpresent-core";

export default function useOnlineUsers() {
  const [singleOnlineUsers, setSingleOnlineUsers] = useState(0);
  const [groupOnlineUsers, setGroupOnlineUsers] = useState(0);

  useEffect(() => {
    const unsubscribe = listenToCurrentOnlineUsers((single, group) => {
      setSingleOnlineUsers(single);
      setGroupOnlineUsers(group);
    });

    return () => {
      unsubscribe();
    };
  }, [setSingleOnlineUsers, setGroupOnlineUsers]);

  return singleOnlineUsers + groupOnlineUsers;
}
