import { useState, useEffect } from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Button,
  Progress,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchEvent,
  fetchNumberOfRecordingsForEvent,
  fetchRecordingsForEvent,
  fetchNumberGroupRecordingUploadsForEvent,
  fetchGroupRecordingsForEvent,
} from "@michaelprichardson/cloudpresent-core";
import moment from 'moment';
import clsx from 'clsx';
import EditEventModal from "components/EditEventModal/EditEventModal";
import Loading from "components/Loading/Loading";

function Event({ match }) {
  const [loadingRecordings, setLoadingRecordings] = useState(true);
  const [loadingGroupRecordings, setLoadingGroupRecordings] = useState(true);
  const [eventId, setEventId] = useState('');
  const [event, setEvent] = useState();
  const [numberRecordings, setNumberRecordings] = useState(0);
  const [numberCompletedRecordings, setNumberCompletedRecordings] = useState(0);
  const [numberGroupRecordings, setNumberGroupRecordings] = useState(0);
  const [numberCompletedGroupRecordings, setNumberCompletedGroupRecordings] = useState(0);

  const [recordings, setRecordings] = useState([]);
  const [groupRecordings, setGroupRecordings] = useState([]);

  const [showEditEventDialog, setShowEditEventDialog] = useState(false);
  
  useEffect(() => {
    const getStats = async () => {
      const id = match.params.eventId;
      setEventId(id);
      const eventDoc = await fetchEvent(id);
      setEvent(eventDoc);

      const recordings = await fetchNumberOfRecordingsForEvent(id);
      const groupRecordings = await fetchNumberGroupRecordingUploadsForEvent(
        id
      );

      setNumberRecordings(recordings.total);
      setNumberCompletedRecordings(recordings.completed);
      
      setNumberGroupRecordings(groupRecordings.total);
      setNumberCompletedGroupRecordings(groupRecordings.completed);

      const recordingDocs = await fetchRecordingsForEvent(id, 10);
      setRecordings(recordingDocs);
      setLoadingRecordings(false);

      const groupRecordingDocs = await fetchGroupRecordingsForEvent(id, 10);
      setGroupRecordings(groupRecordingDocs);
      setLoadingGroupRecordings(false);
    };

    getStats();
  }, []);

  const onEventUpdated = async () => {
    const eventDoc = await fetchEvent(eventId);
    setEvent(eventDoc);
  }

  return (
    <>
      <AlternativeHeader
        name={event?.name}
        parentName="Events"
        parentLink="/dashboard/events"
        buttonTitle="Edit"
        buttonClicked={() => setShowEditEventDialog(true)}
      />

      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Completed Recordings
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {`${numberCompletedRecordings}/${numberRecordings}`}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max={numberRecordings}
                      value={numberCompletedRecordings}
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Completed Groups
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {`${numberCompletedGroupRecordings}/${numberGroupRecordings}`}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max={numberGroupRecordings}
                      value={numberCompletedGroupRecordings}
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Total Recordings
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {numberRecordings}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max={numberRecordings + numberGroupRecordings}
                      value={numberRecordings}
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Total Groups
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {numberGroupRecordings}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max={numberRecordings + numberGroupRecordings}
                      value={numberGroupRecordings}
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Recordings</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href={`${eventId}/recordings`}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {!loadingRecordings && (
                    recordings.map((recording) => {
                      return (
                        <tr key={`recording-${recording.id}`}>
                          <td>
                            <a
                              className="font-weight-bold"
                              href={`/dashboard/recording/${recording.id}`}
                            >
                              {recording.id}
                            </a>
                          </td>
                          <td>{recording.userName}</td>
                          <td>{recording.userEmail}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i
                                className={clsx({
                                  "bg-warning": !recording.completed,
                                  "bg-success": recording.completed,
                                })}
                              />
                              <span className="status">
                                {recording.completed ? "complete" : "editing"}
                              </span>
                            </Badge>
                          </td>
                          <td>
                            <span className="text-muted">
                              {moment
                                .unix(recording.created.seconds || 0)
                                .format("DD/MM/YYYY")}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              {loadingRecordings && (
                <div className="p-3">
                  <Loading />
                </div>
              )}
            </Card>
          </Col>

          <Col md="12">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Group Recordings</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href={`${eventId}/group-recordings`}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Topic</th>
                    <th>Host</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {!loadingGroupRecordings &&
                    groupRecordings.map((recording) => {
                      return (
                        <tr
                          key={`group-recording-${recording.eventId}-${recording.id}`}
                        >
                          <td>
                            <a
                              className="font-weight-bold"
                              href={`/dashboard/group-recording/${recording.id}/sessions`}
                            >
                              {recording.id}
                            </a>
                          </td>
                          <td>{recording.topic}</td>
                          <td>{recording.host.email}</td>
                          <td>
                            <span className="text-muted">
                              {moment
                                .unix(recording.created.seconds || 0)
                                .format("DD/MM/YYYY")}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {loadingGroupRecordings && (
                <div className="p-3">
                  <Loading />
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>

      {event && (
        <EditEventModal
          event={event}
          showModal={showEditEventDialog}
          setShowModal={setShowEditEventDialog}
          onEventUpdated={onEventUpdated}
        />
      )}
    </>
  );
}

export default Event;
