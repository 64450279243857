import { useState, useEffect } from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchNumberOfTranscriptions,
  fetchTranscriptions,
} from "@michaelprichardson/cloudpresent-core";
import moment from 'moment';
import clsx from 'clsx';
import Loading from "components/Loading/Loading";

const PER_PAGE = 10;

function TranscriptionList() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [transcriptions, setTranscriptions] = useState([])

  useEffect(() => { loadTranscriptions() }, []);

  const loadTranscriptions = async () => {
    setLoading(true);
    const nEventDocs = await fetchNumberOfTranscriptions();
    const nPages = Math.ceil(nEventDocs / PER_PAGE);
    setNumberOfPages(nPages);
    setCurrentPage(0);

    const transcriptionDocs = await fetchTranscriptions(PER_PAGE);
    setTranscriptions(transcriptionDocs);

    setLoading(false);
  }

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const eventDocs = await fetchTranscriptions(
        transcriptions[transcriptions.length - 1].doc,
        undefined,
        PER_PAGE
      );
      setTranscriptions(eventDocs);
    } else {
      const eventDocs = await fetchTranscriptions(
        transcriptions[0].doc,
        PER_PAGE
      );
      setTranscriptions(eventDocs);
    }
    
    setCurrentPage(nextPage);
    setLoading(false);
  }

  const determineState = (state) => {
    if (state === "uploaded") {
      return (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-primary" />
          <span className="status">Uploaded</span>
        </Badge>
      );
    } else if (state === "processing") {
      return (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-warning" />
          <span className="status">Processing</span>
        </Badge>
      );
    } else if (state === "complete") {
      return (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-success" />
          <span className="status">Complete</span>
        </Badge>
      );
    } else if (state === "error") {
      return (
        <Badge color="" className="badge-dot mr-4">
          <i className="bg-danger" />
          <span className="status">Error</span>
        </Badge>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <AlternativeHeader name="Transcriptions" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col className="text-right" xs="12">
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  id="create-event-button"
                  href="add-transcription"
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Create Transcription</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>User</th>
                <th>Event</th>
                <th>Created</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            {!loading ? (
              <tbody>
                {transcriptions.map((transcription) => {
                  return (
                    <tr key={`transcription-${transcription.id}`}>
                      <td className="table-user">{transcription.id}</td>
                      <td>{transcription.userName}</td>
                      <td>{transcription.eventName}</td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(transcription.created.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td>{determineState(transcription.state)}</td>
                      <td className="table-actions">
                        {transcription.subtitles && (
                          <>
                            <a
                              className="table-action"
                              id={`subtitle-${transcription.id}`}
                              href={transcription.subtitles}
                              download
                            >
                              <i class="fas fa-closed-captioning"></i>
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`subtitle-${transcription.id}`}
                            >
                              SRT File
                            </UncontrolledTooltip>
                          </>
                        )}

                        {transcription.transcript && (
                          <>
                            <a
                              className="table-action"
                              id={`transcript-${transcription.id}`}
                              href={transcription.transcript}
                              download
                            >
                              <i className="fas fa-scroll" />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target={`transcript-${transcription.id}`}
                            >
                              Transcript
                            </UncontrolledTooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : <Loading/>}
          </Table>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === 0 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={clsx("disabled")}>
                  <PaginationLink>{currentPage + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === numberOfPages - 1 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default TranscriptionList;
