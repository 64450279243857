import React from "react";

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

function AlternativeHeader({
  name,
  parentName,
  parentLink,
  buttonTitle,
  buttonClicked
}) {
  return (
    <>
      <div className="header pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="8" xs="9">
                <h6 className="h2 d-inline-block mb-0">{name}</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links"
                >
                  <BreadcrumbItem>
                    <a href="/dashboard/home">
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  {parentName && (
                    <BreadcrumbItem>
                      <a href={parentLink}>{parentName}</a>
                    </BreadcrumbItem>
                  )}
                  <BreadcrumbItem aria-current="page" className="active">
                    {name}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>

              {buttonTitle && (
                <Col>
                  <div className="col text-right">
                    <Button color="primary" size="sm" onClick={buttonClicked}>
                      {buttonTitle}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AlternativeHeader;
