import React from "react";
import { Spinner } from "reactstrap";

function Loading() {

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner color="primary">Loading...</Spinner>
    </div>
  );
}

export default Loading;
