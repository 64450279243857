import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import Login from "views/pages/auth/Login";
import Register from "views/pages/auth/Register";

function Auth() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-primary");
    document.body.classList.add("bg-image");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-primary");
      document.body.classList.remove("bg-image");
    };
  });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Redirect from="*" to="/login" />
        </Switch>
      </div>
    </>
  );
}

export default Auth;
