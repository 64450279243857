import Alternative from "views/pages/dashboards/Alternative.js";

import Events from "views/pages/events/Events.js";
import ArchivedEvents from "views/pages/events/ArchivedEvents.js";
import Event from "views/pages/events/Event.js";
import EventRecordings from "views/pages/events/EventRecordings";
import EventGroupRecordings from "views/pages/events/EventGroupRecordings";

import Recording from "views/pages/recordings/Recording";
import AllRecordings from "views/pages/recordings/AllRecordings";

import GroupRecording from "views/pages/groupRecordings/GroupRecording";
import AllGroupRecordings from "views/pages/groupRecordings/AllGroupRecordings";
import GroupRecordingSessions from "views/pages/groupRecordings/GroupRecordingSessions";

import TranscriptionList from "views/pages/transcriptions/TranscriptionsList";
import AddTranscription from "views/pages/transcriptions/AddTranscription";

// Levels of auth
// 9: Admin
// 5: Editor

// NOTE: Remember to put the most detailed url first
const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home text-primary",
    component: Alternative,
    layout: "/dashboard",
    role: 0,
  },
  {
    path: "/events",
    name: "Events",
    icon: "fas fa-calendar-week text-primary",
    component: Events,
    layout: "/dashboard",
    role: 0,
  },
  {
    path: "/event/:eventId/recordings",
    name: "Event Recordings",
    icon: "fas fa-calendar-week text-primary",
    component: EventRecordings,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/event/:eventId/group-recordings",
    name: "Event Recordings",
    icon: "fas fa-calendar-week text-primary",
    component: EventGroupRecordings,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/event/:eventId",
    name: "Event",
    icon: "fas fa-calendar-week text-primary",
    component: Event,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/recording/:recordingId",
    name: "Recording",
    icon: "fas fa-user text-primary",
    component: Recording,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/recordings",
    name: "Recordings",
    icon: "fas fa-user text-primary",
    component: AllRecordings,
    layout: "/dashboard",
    role: 0,
  },
  {
    path: "/group-recording/:groupId/sessions",
    name: "Group Recording Sessions",
    icon: "fas fa-users text-primary",
    component: GroupRecordingSessions,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/group-recording/:recordingId",
    name: "Group Recording",
    icon: "fas fa-users text-primary",
    component: GroupRecording,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/group-recordings",
    name: "Group Recordings",
    icon: "fas fa-users text-primary",
    component: AllGroupRecordings,
    layout: "/dashboard",
    role: 0,
  },
  {
    path: "/transcriptions",
    name: "Transcriptions",
    icon: "fas fa-closed-captioning text-primary",
    component: TranscriptionList,
    layout: "/dashboard",
    role: 0,
  },
  {
    path: "/add-transcription",
    name: "Add Transcription",
    icon: "fas fa-closed-captioning text-primary",
    component: AddTranscription,
    layout: "/dashboard",
    invisible: true,
    role: 0,
  },
  {
    path: "/archived-events",
    name: "Archived Events",
    icon: "fas fa-archive text-primary",
    component: ArchivedEvents,
    layout: "/dashboard",
    role: 0,
  },
  // {
  //   path: "/users",
  //   name: "Users",
  //   icon: "ni ni-circle-08 text-primary",
  //   component: GroupRecordings,
  //   layout: "/dashboard",
  //   role: 9,
  // },
  // {
  //   path: "/platform-settings",
  //   name: "Platform Settings",
  //   icon: "ni ni-ui-04 text-primary",
  //   component: GroupRecordings,
  //   layout: "/dashboard",
  //   role: 9,
  // },
];

export default routes;
