import { useEffect, useState } from "react";
import { listenToNumberOfSingleUploads, listenToNumberOfGroupUploads } from "@michaelprichardson/cloudpresent-core";

export default function useNumberUploads() {
  const [singleUploads, setSingleUploads] = useState(0);
  const [groupUploads, setGroupUploads] = useState(0);

  useEffect(() => {
    const unsubscribeSingle = listenToNumberOfSingleUploads((uploads) => { setSingleUploads(uploads); });
    const unsubscribeGroup = listenToNumberOfGroupUploads((uploads) => { setGroupUploads(uploads); });

    return () => {
      unsubscribeSingle();
      unsubscribeGroup();
    };
  }, [setSingleUploads, setGroupUploads]);

  return singleUploads + groupUploads;
}
