import React, { useContext, createElement } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from "providers/AuthProvider";


const AuthRoute = ({ level, render, ...rest }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Route {...rest} render={render}/>
  }
  
  return <Redirect to={'/login'}/>
}

export default AuthRoute;