import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "select2/dist/css/select2.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { AuthProvider } from "providers/AuthProvider";
import AuthRoute from "components/AuthRoute/AuthRoute";

import { initializeDashboard } from "@michaelprichardson/cloudpresent-core";

// @ts-ignore
import packageJson from '../package.json';

initializeDashboard(
  {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  },
  {
    region: process.env.REACT_APP_AWS_REGION,
    bucket: process.env.REACT_APP_AWS_BUCKET,
    identityPoolId: process.env.REACT_APP_AWS_IAM_POOL,
    maxAttempts: 5,
  },
  {
    dsn: "https://4cb9dcad7d374091911319b4c08364e1@o510792.ingest.sentry.io/6022925",
    environment: process.env.REACT_APP_SENTRY_ENV || "devlopment",
    release: packageJson.version,
  },
  process.env.REACT_APP_SENTRY_ENV === "devlopment",
);

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <Switch> 
        <AuthRoute path="/dashboard" render={(props) => <AdminLayout {...props} />} />
        <Route path="/login" render={(props) => <AuthLayout {...props} />} />
        {/* <Route path="/register" render={(props) => <AuthLayout {...props} />} /> */}

        <Redirect from = "*" to="/dashboard/home" />
      </Switch>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
