import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchRecording,
  updateRecordingState,
} from "@michaelprichardson/cloudpresent-core";
import moment from 'moment';

function Recording({ match }) {
  const [loading, setLoading] = useState(true);
  const [recordingId, setRecordingId] = useState('');
  const [recording, setRecording] = useState();
  const [recordingState, setRecordingState] = useState('pending');

  useEffect(() => {
    const getRecording = async () => {
      const id = match.params.recordingId;
      setRecordingId(id);

      const recordingDoc = await fetchRecording(id);
      setRecording(recordingDoc);
      setRecordingState(recordingDoc.state);

      setLoading(false);
    };

    getRecording();
  }, [match]);

  const onRecordingStateChanged = async (e) => {
    const state = e.target.value;
    setRecordingState(state);
    await updateRecordingState(recordingId, state);
  }

   const stateColor = (text) => {
     if (text?.toLowerCase() === "complete") {
       return "success";
     }
     return "primary";
   };

  return (
    <>
      <AlternativeHeader name="Recording" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details</h3>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <>
                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Participant Name
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{recording?.userName}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Participant Email
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{recording?.userEmail}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Uploaded
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">
                            {moment
                              .unix(recording?.created.seconds || 0)
                              .format("HH:mm DD/MM/YYYY")}
                          </h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Takes
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">
                            {recording?.takes || <i>No takes</i>}
                          </h5>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Status</h3>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <Row className="py-3 align-items-center">
                      <Col sm="12">
                        <Form>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect2"
                            >
                              Recording Status
                            </label>
                            <Input
                              id="exampleFormControlSelect2"
                              type="select"
                              value={recordingState}
                              onChange={(e) => onRecordingStateChanged(e)}
                            >
                              <option value="pending">Pending</option>
                              <option value="editing">Editing</option>
                              <option value="completed">Completed</option>
                              <option value="ignored">Ignored</option>
                            </Input>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Takes</h3>
                </CardHeader>
                <CardBody>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Take</th>
                        <th>Deleted</th>
                        <th>Notes</th>
                        <th>Files</th>
                        <th>State</th>
                        <th>Extra</th>
                      </tr>
                    </thead>
                    {!loading && (
                      <tbody className="list">
                        {recording.files.map((file) => {
                          return (
                            <tr key={`recording-${recording.id}-${file.take}`}>
                              <td>{file.take}</td>
                              <td>
                                {file.deleted ? (
                                  <Badge color="danger">Yes</Badge>
                                ) : (
                                  <Badge color="success">No</Badge>
                                )}
                              </td>
                              <td>
                                {file.notes}
                              </td>
                              <td className="table-actions">
                                <Row>
                                  {file.camera?.url ? (
                                    <>
                                      <a
                                        className="table-action"
                                        href={file.camera.url}
                                        id={`tooltip-camera-${recording.id}`}
                                        download=""
                                      >
                                        <i className="fas fa-video" />
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <btn
                                        className="table-action table-action-disabled"
                                        id={`tooltip-camera-${recording.id}-${file.take}`}
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-video" />
                                      </btn>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`tooltip-camera-${recording.id}-${file.take}`}
                                      >
                                        No camera recording
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </Row>

                                <Row>
                                  {file.screen?.url ? (
                                    <>
                                      <a
                                        key={`tooltip-screen-${recording.id}-${file.take}`}
                                        id={`tooltip-screen-${recording.id}-${file.take}`}
                                        className="table-action"
                                        href={file.screen.url}
                                        download=""
                                      >
                                        <i className="fas fa-desktop" />
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <btn
                                        className="table-action table-action-disabled"
                                        id={`tooltip-screen-${recording.id}-${file.take}`}
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-desktop" />
                                      </btn>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`tooltip-screen-${recording.id}-${file.take}`}
                                      >
                                        No screen recording
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </Row>

                                <Row>
                                  {file.assets?.url ? (
                                    <>
                                      <a
                                        className="table-action"
                                        href={file.assets.url}
                                        id={`tooltip-assets-${recording.id}`}
                                        download=""
                                      >
                                        <i className="far fa-file-powerpoint" />
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <btn
                                        className="table-action table-action-disabled"
                                        id={`tooltip-assets-${recording.id}-${file.take}`}
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="far fa-file-powerpoint" />
                                      </btn>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`tooltip-assets-${recording.id}-${file.take}`}
                                      >
                                        No recording assets
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </Row>
                              </td>

                              <td>
                                <Row style={{ padding: "2px" }}>
                                  <Badge color={stateColor(file.camera?.state)}>
                                    {file.camera?.state}
                                  </Badge>
                                </Row>
                                <Row style={{ padding: "2px" }}>
                                  <Badge color={stateColor(file.screen?.state)}>
                                    {file.screen?.state}
                                  </Badge>
                                </Row>
                                <Row style={{ padding: "2px" }}>
                                  <Badge color={stateColor(file.assets?.state)}>
                                    {file.assets?.state}
                                  </Badge>
                                </Row>
                              </td>

                              <td>
                                <Row>
                                  {`Blur: ${
                                    file.camera?.blur ? "Yes" : "No"
                                  }, Mutes: ${
                                    file.camera?.mutes ? "Yes" : "No"
                                  }`}
                                </Row>
                                <Row>{"Nothing"}</Row>
                                <Row>{"Nothing"}</Row>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </Table>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Recording;
