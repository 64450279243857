import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import CreateEventModal from "components/CreateEventModal/CreateEventModal.js";
import {
  fetchNumberOfEvents,
  fetchEvents,
  archiveEvent,
} from "@michaelprichardson/cloudpresent-core";
import moment from 'moment';
import clsx from 'clsx';
import Loading from "components/Loading/Loading";
import Empty from "components/Empty/Empty";

const PER_PAGE = 10;

function Events() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [events, setEvents] = useState([])
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  useEffect(() => { loadEvents() }, []);

  const loadEvents = async () => {
    setLoading(true);
    const nEventDocs = await fetchNumberOfEvents();
    const nPages = Math.ceil(nEventDocs / PER_PAGE);
    setNumberOfPages(nPages);
    setCurrentPage(0);

    const eventDocs = await fetchEvents(PER_PAGE);
    setEvents(eventDocs);

    setLoading(false);
  }

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const eventDocs = await fetchEvents(
        PER_PAGE,
        undefined,
        events[events.length - 1].doc
      );
      setEvents(eventDocs);
    } else {
      const eventDocs = await fetchEvents(
        PER_PAGE, 
        events[0].doc,
      );
      setEvents(eventDocs);
    }
    
    setCurrentPage(nextPage);
    setLoading(false);
  }

  const onEventCreated = () => {
    loadEvents();
  }

  const onArchiveClicked = async (e, eventId) => {
    e.preventDefault();
    await archiveEvent(eventId);
    await loadEvents();
  }

  return (
    <>
      <AlternativeHeader name="Events" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col className="text-right" xs="12">
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  id="create-event-button"
                  onClick={() => setShowCreateEventModal(true)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Create Event</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Expires</th>
                <th>Created at</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {events.map((event) => {
                  return (
                    <tr key={`event-${event.id}`}>
                      <td className="table-user">
                        <a
                          className="font-weight-bold"
                          href={`/dashboard/event/${event.id}`}
                        >
                          {event.name}
                        </a>
                      </td>
                      <td>{event.company}</td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(event.expires.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(event.created.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td>
                        {event.completed ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            <span className="status">complete</span>
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            <span className="status">pending</span>
                          </Badge>
                        )}
                      </td>
                      <td className="table-actions">
                        <span
                          className="table-action"
                          id={`archive-${event.id}`}
                          onClick={(e) => onArchiveClicked(e, event.id)}
                        >
                          <i className="fas fa-archive" />
                        </span>
                        <UncontrolledTooltip
                          delay={0}
                          target={`archive-${event.id}`}
                        >
                          Archive event
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>

          {loading && (
            <div className="p-3">
              <Loading />
            </div>
          )}

          {!loading && events.length === 0 && (
            <div className="p-3">
              <Empty title={"There are no events..."} />
            </div>
          )}

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === 0 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={clsx("disabled")}>
                  <PaginationLink>{currentPage + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === numberOfPages - 1 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </Container>

      <CreateEventModal
        showModal={showCreateEventModal}
        setShowModal={setShowCreateEventModal}
        onEventCreated={onEventCreated}
      />
    </>
  );
}

export default Events;
