import _ from "lodash";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";
import { updateEventExpiryDate } from "@michaelprichardson/cloudpresent-core";

function EditEventModal({ event, showModal, setShowModal, onEventUpdated }) {
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    setExpiryDate(event.expires.toDate());
  }, [event])

  useEffect(() => {
    const validDate = moment(expiryDate).unix() > moment().unix();
    setEnableEdit(validDate);
  }, [expiryDate])

  const onEventExpiryChanged = (event) => {
    setExpiryDate(new Date(event.target.value));
  }

  const updateEventClicked = async () => {
    setEnableEdit(false);
    await updateEventExpiryDate(event.id, expiryDate);
    onEventUpdated();
    setExpiryDate(new Date())
    setShowModal(false);
    setEnableEdit(true);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        backdrop={false}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Event
          </h6>
        </div>
        <div className="modal-body">
          <Form autoComplete="off">
            <FormGroup>

              <label
                className="form-control-label"
                htmlFor="eventExpiryInput"
              >
                Expiry Date:
              </label>
              <Input
                value={moment(expiryDate).format('YYYY-MM-DD')}
                id="eventExpiryInput"
                type="date"
                min={moment().format('YYYY-MM-DD')}
                onChange={e => onEventExpiryChanged(e)}
              />

            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            disabled={!enableEdit}
            onClick={updateEventClicked}
          >
            Update
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EditEventModal;
