import moment from "moment";
import { useState, useEffect } from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";
import { createEvent } from '@michaelprichardson/cloudpresent-core';

function CreateEventModal({ showModal, setShowModal, onEventCreated }) {
  const [companyName, setCompanyName] = useState('');
  const [eventName, setEventName] = useState('');
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [enableCreate, setEnableCreate] = useState(false);

  useEffect(() => {
    const validDate = moment(expiryDate).unix() > moment().unix();
    setEnableCreate(companyName.length !== 0 && eventName.length !== 0 && validDate);
  }, [companyName, eventName, expiryDate])

  const onCompanyNameChanged = (event) => {
    setCompanyName(event.target.value);
  }

  const onEventNameChanged = (event) => {
    setEventName(event.target.value);
  }

  const onEventExpiryChanged = (event) => {
    setExpiryDate(new Date(event.target.value));
  }

  const createEventClicked = async () => {
    setEnableCreate(false);
    const { error } = await createEvent(companyName, eventName, expiryDate);

    if (!error) {
      onEventCreated();
      setShowModal(false);
    }
    setCompanyName("");
    setEventName("");
    setExpiryDate(new Date());
    setEnableCreate(true);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        backdrop={false}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Create Event
          </h6>
        </div>
        <div className="modal-body">
          <Form autoComplete="off">
            <FormGroup>

              <label
                className="form-control-label"
                htmlFor="companyNameInput"
              >
                Company Name:
              </label>
              <Input
                id="companyNameInput"
                placeholder="The name of the company"
                type="text"
                value={companyName}
                onChange={e => onCompanyNameChanged(e)}
              />

              <label
                className="form-control-label"
                htmlFor="eventNameInput"
              >
                Event Name:
              </label>
              <Input
                id="eventNameInput"
                placeholder="The name of the event"
                type="text"
                value={eventName}
                onChange={e => onEventNameChanged(e)}
              />

              <label
                className="form-control-label"
                htmlFor="eventExpiryInput"
              >
                Expiry Date:
              </label>
              <Input
                value={moment(expiryDate).format('YYYY-MM-DD')}
                id="eventExpiryInput"
                type="date"
                min={moment().format('YYYY-MM-DD')}
                onChange={e => onEventExpiryChanged(e)}
              />
              
            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            disabled={!enableCreate}
            onClick={createEventClicked}
          >
            Create
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default CreateEventModal;
