import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchEvent,
  fetchNumberOfRecordingsForEvent,
  fetchRecordingsForEvent,
} from "@michaelprichardson/cloudpresent-core";
import { urlSafeName } from 'utils/formatting';
import moment from 'moment';
import clsx from 'clsx';

const PER_PAGE = 10;

function EventRecordings({ match }) {
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [event, setEvent] = useState();
  const [recordings, setRecordings] = useState([]);
  const [numRecordings, setNumRecordings] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    const getRecordings = async () => {
      const id = match.params.eventId;
      setEventId(id);
      const eventDoc = await fetchEvent(id);
      setEvent(eventDoc);

      const nRecordingDocs = await fetchNumberOfRecordingsForEvent(id);
      setNumRecordings(nRecordingDocs.total);
      const nPages = Math.ceil(nRecordingDocs.total / PER_PAGE);
      setNumberOfPages(nPages);
      setCurrentPage(0);

      const recordingDocs = await fetchRecordingsForEvent(id, PER_PAGE);
      setRecordings(recordingDocs);
    };

    getRecordings();
  }, [match]);

  const onDeleteRecordingClicked = async (e, recordingId) => {
    e.preventDefault();
    console.log(`Delete recording ${recordingId}`);
  }

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const recordingDocs = await fetchRecordingsForEvent(
        eventId,
        PER_PAGE,
        undefined,
        recordings[recordings.length - 1].doc
      );
      setRecordings(recordingDocs);
    } else {
      const recordingDocs = await fetchRecordingsForEvent(
        eventId,
        PER_PAGE,
        recordings[0].doc
      );
      setRecordings(recordingDocs);
    }
    
    setCurrentPage(nextPage);
    setLoading(false);
  }

  let recordingLink;
  if (event) {
    recordingLink = `${process.env.REACT_APP_PLATFORM_URL}/record/${urlSafeName(event.company)}/${urlSafeName(
      event.slug
    )}`;
  }

  return (
    <>
      <AlternativeHeader name={`${event?.name} Recordings`} />
      <Container className="mt--6" fluid>

        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details</h3>
                </CardHeader>
                <CardBody>
                  {!loading && (
                    <>
                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Event Name
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{event?.name}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            URL
                          </small>
                        </Col>
                        <Col sm="8">
                          <small>
                            <a href={recordingLink} target="_blank" rel="noopener noreferrer">
                              {recordingLink}
                            </a>
                          </small>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Total Recordings
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{numRecordings}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Created
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{moment.unix(event?.created.seconds || 0).format('HH:mm DD/MM/YYYY')}</h5>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card>
              <CardHeader className="border-0">
                <Row></Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                {!loading && (
                  <tbody className="list">
                  {recordings.map(recording => {
                    return (
                      <tr key={`recording-${recording.id}`}>
                        <td>
                          <a
                            className="font-weight-bold"
                            href={`/dashboard/recording/${recording.id}`}
                          >
                            {recording.id}
                          </a>
                        </td>
                        <td>
                        {recording.userName}
                        </td>
                        <td>
                        {recording.userEmail}
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className={clsx({
                              'bg-warning': !recording.completed,
                              'bg-success': recording.completed,
                            })} />
                            <span className="status">{recording.completed ? 'complete' : 'editing'}</span>
                          </Badge>
                        </td>
                        <td>
                          <span className="text-muted">
                          {moment.unix(recording.created.seconds || 0).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td className="table-actions">
                          <a
                            className="table-action table-action-delete"
                            href="#pablo"
                            id={`tooltip-delete-${recording.id}`}
                            onClick={e => onDeleteRecordingClicked(e, recording.id)}
                          >
                            <i className="fas fa-trash" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`tooltip-delete-${recording.id}`}>
                            Delete recording
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                )}
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={clsx({
                      'disabled': currentPage === 0 || loading
                    })}>
                      <PaginationLink
                        onClick={e => pageChange(e, currentPage - 1)}
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={clsx('disabled')}>
                      <PaginationLink>
                        {currentPage + 1}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={clsx({
                      'disabled': currentPage === numberOfPages - 1 || loading
                    })}>
                      <PaginationLink
                        onClick={e => pageChange(e, currentPage + 1)}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EventRecordings;
