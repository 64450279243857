import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import CreateGroupRecordingModal from "components/CreateGroupRecordingModal/CreateGroupRecordingModal.js";
import {
  fetchEvent,
  fetchNumberGroupRecordingSessionsForEvent,
  fetchGroupRecordingsForEvent,
} from '@michaelprichardson/cloudpresent-core';
import moment from 'moment';
import clsx from 'clsx';

const PER_PAGE = 10;

function EventGroupRecordings({ match }) {
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState('');
  const [event, setEvent] = useState();
  const [groupRecordings, setGroupRecordings] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);

  useEffect(() => { loadRecordings() }, []);

  const loadRecordings = async () => {
    const id = match.params.eventId;
    setEventId(id);
    const eventDoc = await fetchEvent(id);
    setEvent(eventDoc);

    const nRecordingDocs = await fetchNumberGroupRecordingSessionsForEvent(id);
    const nPages = Math.ceil(nRecordingDocs.total / PER_PAGE);
    setNumberOfPages(nPages);
    setCurrentPage(0);

    const groupRecordingDocs = await fetchGroupRecordingsForEvent(id, PER_PAGE);
    console.log(groupRecordingDocs);
    setGroupRecordings(groupRecordingDocs);
  };

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const groupRecordingDocs = await fetchGroupRecordingsForEvent(
        eventId, 
        PER_PAGE, 
        undefined,
        groupRecordings[groupRecordings.length - 1].doc,
      );
      setGroupRecordings(groupRecordingDocs);
    } else {
      const groupRecordingDocs = await fetchGroupRecordingsForEvent(
        eventId,
        PER_PAGE,
        groupRecordings[0].doc,
      );
      setGroupRecordings(groupRecordingDocs);
    }
    
    setCurrentPage(nextPage);
    setLoading(false);
  }

  const onCreateGroupRecordingClicked = () => {
    loadRecordings();
  }

  return (
    <>
      <AlternativeHeader name={`${event?.name} Group Recordings`} />
      <Container className="mt--6" fluid>
        
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col className="text-right" xs="12">
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  id="create-event-button"
                  onClick={() => setShowCreateSessionModal(true)}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Create Group Recording</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Topic</th>
                <th>Host</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody className="list">
              {groupRecordings.map(recording => {
                return (
                  <tr key={`group-recording-${recording.eventId}-${recording.id}`}>
                    <td>
                      <a
                        className="font-weight-bold"
                        href={`/dashboard/group-recording/${recording.id}/sessions`}
                      >
                        {recording.id}
                      </a>
                    </td>
                    <td>
                      {recording.topic}
                    </td>
                    <td>
                      {recording.host.email}
                    </td>
                    <td>
                      <span className="text-muted">
                      {moment.unix(recording.created.seconds || 0).format('HH:mm DD/MM/YYYY')}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={clsx({
                  'disabled': currentPage === 0 || loading
                })}>
                  <PaginationLink
                    onClick={e => pageChange(e, currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={clsx('disabled')}>
                  <PaginationLink>
                    {currentPage + 1}
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={clsx({
                  'disabled': currentPage === numberOfPages - 1 || loading
                })}>
                  <PaginationLink
                    onClick={e => pageChange(e, currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </Container>

      <CreateGroupRecordingModal
        event={event}
        title={'Create Group Session'}
        showModal={showCreateSessionModal}
        setShowModal={setShowCreateSessionModal}
        onGroupSessionCreated={onCreateGroupRecordingClicked}
      />
    </>
  );
}

export default EventGroupRecordings;
