import { useEffect, useState } from "react";
// react plugin for creating vector maps
// javascipt plugin for creating charts
import Chart from "chart.js";
import classnames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import { colors, homeScreenChartOptions } from "variables/charts";

import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";
import {
  fetchNumberUploads,
  fetchUploadMinutes,
  calculateDateKeys,
} from "@michaelprichardson/cloudpresent-core";

// TODO: Need to update these to ues the core library
import useOnlineUsers from "../../../hooks/analytics/useOnlineUsers/useOnlineUsers";
import useNumberOfCompletedEvents from "../../../hooks/analytics/useNumberOfCompletedEvents/useNumberOfCompletedEvents";
import useNumberUploads from "../../../hooks/analytics/useNumberUploads/useNumberUploads";
import useUploadsDuration from "../../../hooks/analytics/useUploadsDuration/useUploadsDuration";
import Loading from "components/Loading/Loading";

function Alternative() {
  const [loading, setLoading] = useState(true);
  const [activeNav, setActiveNav] = useState(1);
  const [statsData, setStatsData] = useState();
  const [numberOfUploadsData, setNumberOfUploadsData] = useState();
  const [uploadedMinutesData, setUploadedMinutesData] = useState();

  // Analytics hooks
  const onlineUsers = useOnlineUsers();
  const { numberOfEvents, numberOfCompletedEvents } = useNumberOfCompletedEvents();
  const totalUploads = useNumberUploads();
  const totalDuration = useUploadsDuration();
  

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    fetchAllAnalytics(activeNav === 1);
  }, [activeNav]);

  const fetchAllAnalytics = async (week) => {
    setLoading(true);

    await setupNumberUploadsChart(week);
    await setupUploadMinutesChart(week);
    
    setLoading(false);
  };

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    fetchAllAnalytics(index === 1);
  };

  const setupNumberUploadsChart = async (week) => {
    const days = calculateDateKeys(new Date(), week ? 7 : 30);
    const data = await fetchNumberUploads(days);

    setNumberOfUploadsData({
      labels: data.labels,
      datasets: [
        {
          label: "Singles",
          borderColor: colors.theme.primary,
          backgroundColor: colors.theme.primary,
          fill: false,
          data: data.single,
        },
        {
          label: "Groups",
          borderColor: colors.theme.success,
          backgroundColor: colors.theme.success,
          fill: false,
          data: data.group,
        },
      ],
      options: homeScreenChartOptions("Upload"),
    });
  }

  const setupUploadMinutesChart = async (week) => {
    const days = calculateDateKeys(new Date(), week ? 7 : 30);
    const data = await fetchUploadMinutes(days);

    setUploadedMinutesData({
      labels: data.labels,
      datasets: [
        {
          label: "Singles",
          borderColor: colors.theme.primary,
          backgroundColor: colors.theme.primary,
          fill: false,
          data: data.single,
        },
        {
          label: "Groups",
          borderColor: colors.theme.success,
          backgroundColor: colors.theme.success,
          fill: false,
          data: data.group,
        },
      ],
      options: homeScreenChartOptions("Minute"),
    });
  };

  return (
    <>
      <AlternativeHeader name={"Home"} />
      <Container className="mt--6" fluid>
        <Row>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Online Users
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {onlineUsers || 0}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max="100"
                      value="30"
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Completed Events
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {numberOfCompletedEvents}/{numberOfEvents}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max="100"
                      value="50"
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Total recordings
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {totalUploads}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max="100"
                      value="80"
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" xl="3">
            <Card className="bg-gradient-primary border-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0 text-white"
                    >
                      Minutes recorded
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-0 text-white">
                      {totalDuration}
                    </span>
                    <Progress
                      className="progress-xs mt-3 mb-0"
                      max="100"
                      value="90"
                      color="success"
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-muted text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="h3 mb-0">
                      Uploads in the last {activeNav === 1 ? "week" : "month"}{" "}
                      (Count)
                    </h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!loading ? (
                  <div className="chart">
                    <Line
                      data={numberOfUploadsData}
                      options={numberOfUploadsData.options}
                      id="chart-sales"
                      className="chart-canvas"
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md="6">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-muted text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="h3 mb-0">
                      Uploads in the last {activeNav === 1 ? "week" : "month"}{" "}
                      (Minutes)
                    </h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>

                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {!loading ? (
                  <div className="chart">
                    <Bar
                      data={uploadedMinutesData}
                      options={uploadedMinutesData.options}
                      id="chart-sales"
                      className="chart-canvas"
                    />
                  </div>
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Alternative;
