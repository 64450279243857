import { useEffect, useState } from "react";
import { listenToSingleUploadsDuration, listenToGroupUploadsDuration } from "@michaelprichardson/cloudpresent-core";

export default function useNumberUploads() {
  const [singleDuration, setSingleDuration] = useState(0);
  const [groupDuration, setGroupDuration] = useState(0);

  useEffect(() => {
    const unsubscribeSingle = listenToSingleUploadsDuration((duration) => { setSingleDuration(duration);  });
    const unsubscribeGroup = listenToGroupUploadsDuration((duration) => { setGroupDuration(duration); });

    return () => {
      unsubscribeSingle();
      unsubscribeGroup();
    };
  }, [setSingleDuration, setGroupDuration]);

  return singleDuration + groupDuration;
}
