import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardFooter,
  Table,
  Container,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchNumberOfGroupRecordings,
  fetchGroupRecordings,
} from "@michaelprichardson/cloudpresent-core";
import moment from "moment";
import clsx from "clsx";
import Loading from "components/Loading/Loading";
import Empty from "components/Empty/Empty";

const PER_PAGE = 10;

function AllGroupRecordings() {
  const [loading, setLoading] = useState(false);
  const [groupRecordings, setGroupRecordings] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  useEffect(() => {
    const getRecordings = async () => {
      setLoading(true);
      const nRecordingDocs = await fetchNumberOfGroupRecordings();
      const nPages = Math.ceil(nRecordingDocs.total / PER_PAGE);
      setNumberOfPages(nPages);
      setCurrentPage(0);

      const recordingDocs = await fetchGroupRecordings(PER_PAGE);
      setGroupRecordings(recordingDocs);
      setLoading(false);
    };

    getRecordings();
  }, []);

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const recordingDocs = await fetchGroupRecordings(
        PER_PAGE,
        undefined,
        groupRecordings[groupRecordings.length - 1].doc
      );
      setGroupRecordings(recordingDocs);
    } else {
      const recordingDocs = await fetchGroupRecordings(
        PER_PAGE,
        groupRecordings[0].doc
      );
      setGroupRecordings(recordingDocs);
    }

    setCurrentPage(nextPage);
    setLoading(false);
  };

  return (
    <>
      <AlternativeHeader name="Group Recordings" />
      <Container className="mt--6" fluid>
        <Card>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>ID</th>
                <th>Topic</th>
                <th>Event</th>
                <th>Status</th>
                <th>Created</th>
              </tr>
            </thead>
            {!loading && (
              <tbody className="list">
                {groupRecordings.map((recording) => {
                  return (
                    <tr key={`recording-${recording.id}`}>
                      <td>
                        <a
                          className="font-weight-bold"
                          href={`/dashboard/group-recording/${recording.groupId}/sessions`}
                        >
                          {recording.id}
                        </a>
                      </td>
                      <td>{recording.topic}</td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href={`/dashboard/event/${recording.eventId}`}
                        >
                          {recording.eventId}
                        </a>
                      </td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          <i
                            className={clsx({
                              "bg-danger": recording.state === "pending",
                              "bg-warning": recording.state === "editing",
                              "bg-success": recording.completed,
                              "bg-secondary": recording.state === "ignored",
                            })}
                          />
                          <span className="status">{recording.state}</span>
                        </Badge>
                      </td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(recording.created.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>

          {loading && (
            <div className="p-3">
              <Loading />
            </div>
          )}

          {(!loading && groupRecordings.length === 0) && (
            <div className="p-3">
              <Empty title={"There are no group recordings..."} />
            </div>
          )}

          {!loading && (
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem
                    className={clsx({
                      disabled: currentPage === 0 || loading,
                    })}
                  >
                    <PaginationLink
                      onClick={(e) => pageChange(e, currentPage - 1)}
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={clsx("disabled")}>
                    <PaginationLink>{currentPage + 1}</PaginationLink>
                  </PaginationItem>
                  <PaginationItem
                    className={clsx({
                      disabled: currentPage === numberOfPages - 1 || loading,
                    })}
                  >
                    <PaginationLink
                      onClick={(e) => pageChange(e, currentPage + 1)}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          )}
        </Card>
      </Container>
    </>
  );
}

export default AllGroupRecordings;
