import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import NotificationAlert from "react-notification-alert";
import {
  createTranscription,
  deleteTranscription,
  uploadAudioFile,
} from "@michaelprichardson/cloudpresent-core";

function AddTranscription() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [eventName, setEventName] = useState('');
  const [audioFile, setAudioFile] = useState();
  const [allowUpload, setAllowUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    setAllowUpload(userName.length > 0 && eventName.length > 0 && audioFile);
  }, [userName, eventName, audioFile]);

  const onCreateTranscriptionClicked = async () => {
    setLoading(true);
    setUploadProgress(0);

    // Create Firestore entry
    const transcriptionId = await createTranscription(userName, eventName);

    // Upload to S3
    const { success, error } = await uploadAudioFile(transcriptionId, audioFile, (progress) => {
      setUploadProgress(progress.percentage);
    });

    if (success) {
      notify(
        "success",
        "Successfully Created Transcription",
        "The transcription was successfully submitted, you will be notified once it has completed"
      );

      setUserName("");
      setEventName("");
      setAudioFile(undefined);
      history.push("/dashboard/transcriptions");
    } else {
      await deleteTranscription(transcriptionId);
      notify(
        "danger",
        "Error Creating Transcription",
        `There was an error creating the transcription: ${error}`
      );
    }
    
    setLoading(false);
  }

  const notify = (type, title, message) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">{title}</span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: "fas fa-check",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <AlternativeHeader name="Add Transcription" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="userName">
                        User Name
                      </label>
                      <Input
                        id="userName"
                        type="email"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label className="form-control-label" htmlFor="eventName">
                        Event Name
                      </label>
                      <Input
                        id="eventName"
                        type="text"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label className="form-control-label" htmlFor="audioFile">
                        Audio File
                      </label>
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="audioFile"
                          lang="en"
                          type="file"
                          accept=".mp3"
                          onChange={(e) => setAudioFile(e.target.files[0])}
                        />
                      </div>
                    </FormGroup>

                    <Button
                      color="primary"
                      type="button"
                      disabled={!allowUpload || loading}
                      onClick={(e) => onCreateTranscriptionClicked()}
                    >
                      {loading
                        ? `Uploading... (${uploadProgress.toFixed(1)}%)`
                        : "Create Trascription"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
    </>
  );
}

export default AddTranscription;
