import { useState, useEffect } from "react";
import { captureException } from "@sentry/react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { signIn, verifyRecaptcha } from "@michaelprichardson/cloudpresent-core";
import { validateEmail } from "utils/validation";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;;

function Login({ history }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
    );
  }, []);

  useEffect(() => {
    setError('')
  }, [email, password, setError]);

  const loginClicked = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Invalid email");
      return;
    }

    if (password.length === 0) {
      setError('Missing a password');
      return;
    }

    setLoading(true);
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(RECAPTCHA_KEY, {
          action: "submit",
        });

        const result = await verifyRecaptcha(token);
        if (result) {
          const errorCode = await signIn(email, password);
          setLoading(false);
          if (!errorCode) {
            history.push("/dashboard/home");
          } else if (errorCode === "auth/user-not-found" || errorCode === "auth/invalid-email") {
            setError("Email or password is invalid");
          } else {
            setError("Unknown error");
          }
        } else {
          setError('Error verifying you are human');
          setLoading(false);
        }
      } catch (error) {
        captureException(error);
        setError("Error verifying you are human");
        setLoading(false);
      }
    });
  }

  return (
    <>
      <AuthHeader title="Login into your Cloud Present account" />
      <Container>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={(e) => loginClicked(e)}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Signing in..." : "Sign in"}
                    </Button>
                  </div>

                  <div className="text-center text-danger">
                    <small>{error}</small>
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/* <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a className="text-light" href="/register">
                  <small>Create new account</small>
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
