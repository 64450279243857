import { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";
import { createGroupRecording } from "@michaelprichardson/cloudpresent-core";
import { validateEmail } from "utils/validation";

function CreateGroupRecordingModal({ event, title, showModal, setShowModal, onGroupSessionCreated }) {
  const [topic, setTopic] = useState('');
  const [hostName, setHostName] = useState('');
  const [hostEmail, setHostEmail] = useState('');
  const [enableCreate, setEnableCreate] = useState(false);

  useEffect(() => {
    setEnableCreate(topic.length !== 0 && hostName.length !== 0 && validateEmail(hostEmail));
  }, [topic, hostName, hostEmail])

  const onTopicChanged = (event) => {
    setTopic(event.target.value);
  }

  const onHostNameChanged = (event) => {
    setHostName(event.target.value);
  }

  const onHostEmailChanged = (event) => {
    setHostEmail(event.target.value);
  }

  const createEventClicked = async () => {
    setEnableCreate(false);
    const { groupId, error } = await createGroupRecording(event, topic, hostName, hostEmail);

    if (error) {
      console.error(error);
    } else {
      console.info(`Created group recording ${groupId}`);
      onGroupSessionCreated();
      setShowModal(false);
    }
    setTopic('');
    setHostName('');
    setHostEmail('');
    setEnableCreate(true);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        backdrop={false}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {title}
          </h6>
        </div>
        <div className="modal-body">
          <Form autoComplete="off">
            <FormGroup>
              
              <label
                className="form-control-label"
                htmlFor="topicInput"
              >
                Topic:
              </label>
              <Input
                id="topicInput"
                placeholder="Group session topic"
                type="text"
                value={topic}
                onChange={e => onTopicChanged(e)}
              />

              <label
                className="form-control-label"
                htmlFor="hostNameInout"
              >
                Host Name:
              </label>
              <Input
                id="hostNameInout"
                placeholder="Host name"
                type="text"
                value={hostName}
                onChange={e => onHostNameChanged(e)}
              />

              <label
                className="form-control-label"
                htmlFor="hostEmailInput"
              >
                Host Email:
              </label>
              <Input
                id="hostEmailInput"
                placeholder="Host email address"
                type="email"
                value={hostEmail}
                onChange={e => onHostEmailChanged(e)}
              />

            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            disabled={!enableCreate}
            onClick={createEventClicked}
          >
            Create
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default CreateGroupRecordingModal;
