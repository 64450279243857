import md5 from 'md5';

// Convert time from milliseconds int to hh:mm:ss string
export function formatTime(seconds) {
  const mm = seconds / 60;
  const ss = seconds % 60;

  return `${zeroPad(mm)}:${zeroPad(ss)}`;
}

// Add zero padding
export function zeroPad(num, size = 2) {
  let s = Math.floor(num).toString();
  while (s.length < size) { s = '0' + s };
  return s;
}

// Force to URL safe name
export function urlSafeName(text) {
  return text.replace(/[`~!@#$%^&*()_|+=?:'",.<>\{\}\[\]\\\/\s]/g, '-').toLowerCase();
}

export function createSingleId(company, event) {
  return md5(`/${urlSafeName(company)}/${urlSafeName(event)}`);
}

export function createGroupId(company, event, topic) {
  return md5(`/${urlSafeName(company)}/${urlSafeName(event)}/${urlSafeName(topic)}`);
}