import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  UncontrolledTooltip,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchNumberOfArchivedEvents,
  fetchArchivedEvents,
  unarchiveEvent,
} from "@michaelprichardson/cloudpresent-core";
import moment from "moment";
import clsx from "clsx";
import Loading from "components/Loading/Loading";

const PER_PAGE = 10;

function ArchivedEvents() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [archivedEvents, setArchivedEvents] = useState([]);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    setLoading(true);
    const nArchivedEventDocs = await fetchNumberOfArchivedEvents();
    const nPages = Math.ceil(nArchivedEventDocs / PER_PAGE);
    setNumberOfPages(nPages);
    setCurrentPage(0);

    const archivedEventDocs = await fetchArchivedEvents(PER_PAGE);
    setArchivedEvents(archivedEventDocs);

    setLoading(false);
  };

  const pageChange = async (e, nextPage) => {
    e.preventDefault();

    setLoading(true);
    if (currentPage < nextPage) {
      const eventDocs = await fetchArchivedEvents(
        PER_PAGE,
        undefined,
        archivedEvents[archivedEvents.length - 1].doc
      );
      setArchivedEvents(eventDocs);
    } else {
      const eventDocs = await fetchArchivedEvents(
        PER_PAGE,
        archivedEvents[0].doc
      );
      setArchivedEvents(eventDocs);
    }

    setCurrentPage(nextPage);
    setLoading(false);
  };

  const onUnarchiveClicked = async (e, eventId) => {
    e.preventDefault();
    await unarchiveEvent(eventId);
    await loadEvents();
  };

  return (
    <>
      <AlternativeHeader name="Archived Events" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0"></CardHeader>

          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Expires</th>
                <th>Created at</th>
                <th>Status</th>
                <th />
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {archivedEvents.map((event) => {
                  return (
                    <tr key={`event-${event.id}`}>
                      <td className="table-user">
                        <a
                          className="font-weight-bold"
                          href={`/dashboard/event/${event.id}`}
                        >
                          {event.name}
                        </a>
                      </td>
                      <td>{event.company}</td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(event.expires.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {moment
                            .unix(event.created.seconds || 0)
                            .format("DD/MM/YYYY")}
                        </span>
                      </td>
                      <td>
                        {event.completed ? (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-success" />
                            <span className="status">complete</span>
                          </Badge>
                        ) : (
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-warning" />
                            <span className="status">pending</span>
                          </Badge>
                        )}
                      </td>
                      <td className="table-actions">
                        <btn
                          className="table-action"
                          id={`unarchive-${event.id}`}
                          onClick={(e) => onUnarchiveClicked(e, event.id)}
                        >
                          <i className="fas fa-box-open" />
                        </btn>
                        <UncontrolledTooltip
                          delay={0}
                          target={`unarchive-${event.id}`}
                        >
                          Unarchive event
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>

          {loading && (
            <div className="p-3">
              <Loading />
            </div>
          )}

          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === 0 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage - 1)}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem className={clsx("disabled")}>
                  <PaginationLink>{currentPage + 1}</PaginationLink>
                </PaginationItem>
                <PaginationItem
                  className={clsx({
                    disabled: currentPage === numberOfPages - 1 || loading,
                  })}
                >
                  <PaginationLink
                    onClick={(e) => pageChange(e, currentPage + 1)}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default ArchivedEvents;
