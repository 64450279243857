import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import { fetchGroupRecordingSession, updateGroupRecordingState } from "@michaelprichardson/cloudpresent-core";
import moment from "moment";
import Loading from "components/Loading/Loading";

function GroupRecording({ match }) {
  const [loading, setLoading] = useState(true);
  const [groupRecordingId, setGroupRecordingId] = useState("");
  const [groupRecording, setGroupRecording] = useState();
  const [groupRecordingState, setGroupRecordingState] = useState("pending");

  useEffect(() => {
    const getGroupRecording = async () => {
      const id = match.params.recordingId;
      setGroupRecordingId(id);

      const groupRecordingDoc = await fetchGroupRecordingSession(id);
      setGroupRecording(groupRecordingDoc);
      setGroupRecordingState(groupRecordingDoc.state);
      setLoading(false);
    };

    getGroupRecording();
  }, [match]);

  const onGroupRecordingStateChanged = async (e) => {
    const state = e.target.value;
    setGroupRecordingState(state);
    updateGroupRecordingState(groupRecordingId, state);
  };

  const stateColor = (text) => {
    if (text?.toLowerCase() === "complete") {
      return "success";
    }
    return "primary";
  };

  return (
    <>
      <AlternativeHeader name="Group Recording" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details</h3>
                </CardHeader>
                <CardBody>
                  <Row className="py-3 align-items-center">
                    <Col sm="4">
                      <small className="text-uppercase text-muted font-weight-bold">
                        Host Name
                      </small>
                    </Col>
                    <Col sm="8">
                      <h5 className="mb-0">{groupRecording?.host.name}</h5>
                    </Col>
                  </Row>

                  <Row className="py-3 align-items-center">
                    <Col sm="4">
                      <small className="text-uppercase text-muted font-weight-bold">
                        Session Duration
                      </small>
                    </Col>
                    <Col sm="8">
                      <h5 className="mb-0">{groupRecording?.duration}</h5>
                    </Col>
                  </Row>

                  <Row className="py-3 align-items-center">
                    <Col sm="4">
                      <small className="text-uppercase text-muted font-weight-bold">
                        Uploaded
                      </small>
                    </Col>
                    <Col sm="7">
                      <h5 className="mb-0">
                        {moment
                          .unix(groupRecording?.created.seconds || 0)
                          .format("HH:mm DD/MM/YYYY")}
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Status</h3>
                </CardHeader>
                <CardBody>
                  <Row className="py-3 align-items-center">
                    <Col sm="12">
                      <Form>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Recording Status
                          </label>
                          <Input
                            id="exampleFormControlSelect2"
                            type="select"
                            onChange={onGroupRecordingStateChanged}
                          >
                            <option value="pending">Pending</option>
                            <option value="editing">Editing</option>
                            <option value="completed">Completed</option>
                            <option value="ignored">Ignored</option>
                          </Input>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>

          {!loading &&
            groupRecording.takes.map((take) => {
              return (
                <Col key={`group-take-${take.take}`} lg="12">
                  <div className="card-wrapper">
                    <Card>
                      <CardHeader>
                        <h3 className="mb-0">
                          Take {take.take}{" "}
                          {take.deleted ? (
                            <Badge color="danger">Deleted</Badge>
                          ) : null}
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col sm="3">
                            <small className="text-uppercase text-muted font-weight-bold">
                              Take Duration
                            </small>
                          </Col>
                          <Col sm="3">
                            <small className="font-weight-bold">
                              {take.duration}
                            </small>
                          </Col>
                        </Row>

                        <hr />

                        {take.participants
                          .filter((it) => it.role === "host")
                          .map((it) => {
                            return (
                              <Row
                                key={`participant-${it.id}-${take.take}`}
                                className="align-items-center"
                              >
                                <Col sm="7">
                                  <small className="text-uppercase text-muted font-weight-bold">
                                    {`${it.name} (host)`}
                                  </small>
                                  <small className="text-lowercase text-muted font-weight-bold">
                                    {` (${it.email})`}
                                  </small>
                                </Col>

                                <Col sm="1">
                                  {it.camera ? (
                                    <a
                                      className="table-action table-action"
                                      href={it.camera}
                                      download=""
                                    >
                                      <i className="fas fa-video" />
                                    </a>
                                  ) : (
                                    <>
                                      <i
                                        className="table-action table-action fas fa-video"
                                        id={`camera-tooltip-${it.id}`}
                                      />
                                      <UncontrolledTooltip
                                        delay={0}
                                        target={`camera-tooltip-${it.id}`}
                                      >
                                        No camera recording
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                </Col>

                                <Col sm="2">
                                  <Badge color={stateColor(it.camera?.state)}>
                                    {it.camera?.state}
                                  </Badge>
                                </Col>
                                <Col sm="2">
                                  <small>{`Blur: ${
                                    it.camera?.blur ? "Yes" : "No"
                                  }, Mutes: ${
                                    it.camera?.mutes ? "Yes" : "No"
                                  }`}</small>
                                </Col>

                                {it.screenFiles.map((file, index) => {
                                  return (
                                    <>
                                      <Col
                                        sm="7"
                                        key={`screen-${it.id}-${take.take}-times`}
                                      >
                                        <small className="text-muted font-weight-bold">
                                          {`${index + 1}: `}
                                        </small>
                                        <small className="font-weight-bold">
                                          {`${it.screenStarts[index]} - ${it.screenEnds[index]}`}
                                        </small>
                                      </Col>

                                      <Col
                                        sm="1"
                                        key={`screen-${it.id}-${take.take}-download`}
                                      >
                                        <a
                                          key={`screen-tooltip-${it.id}-${index}`}
                                          id={`screen-tooltip-${it.id}-${index}`}
                                          className="table-action table-action ml-2"
                                          href={file.url}
                                          download=""
                                        >
                                          <i className="fas fa-desktop" />
                                        </a>
                                      </Col>
                                      <Col
                                        sm="2"
                                        key={`screen-${it.id}-${take.take}-state`}
                                      >
                                        <Badge color={stateColor(file?.state)}>
                                          {file?.state}
                                        </Badge>
                                      </Col>
                                    </>
                                  );
                                })}
                              </Row>
                            );
                          })}

                        <hr />

                        {take.participants
                          .filter((it) => it.role !== "host")
                          .map((it) => {
                            return (
                              <>
                                <Row
                                  key={`participant-${it.id}-${take.take}`}
                                  className="align-items-center"
                                >
                                  <Col sm="7">
                                    <small className="text-uppercase text-muted font-weight-bold">
                                      {it.name}
                                    </small>
                                    <small className="text-lowercase text-muted font-weight-bold">
                                      {` (${it.email})`}
                                    </small>
                                  </Col>

                                  <Col sm="1">
                                    {it.camera ? (
                                      <a
                                        className="table-action table-action"
                                        href={it.camera}
                                        download=""
                                      >
                                        <i className="fas fa-video" />
                                      </a>
                                    ) : (
                                      <>
                                        <i
                                          className="table-action table-action fas fa-video"
                                          id={`camera-tooltip-${it.id}`}
                                        />
                                        <UncontrolledTooltip
                                          delay={0}
                                          target={`camera-tooltip-${it.id}`}
                                        >
                                          No camera recording
                                        </UncontrolledTooltip>
                                      </>
                                    )}
                                  </Col>

                                  <Col sm="2">
                                    <Badge color={stateColor(it.camera?.state)}>
                                      {it.camera?.state}
                                    </Badge>
                                  </Col>
                                  <Col sm="2">
                                    <small>{`Blur: ${
                                      it.camera?.blur ? "Yes" : "No"
                                    }, Mutes: ${
                                      it.camera?.mutes ? "Yes" : "No"
                                    }`}</small>
                                  </Col>

                                  {it.screenFiles.map((file, index) => {
                                    return (
                                      <Row key={`screen-${it.id}-${take.take}`}>
                                        <Col sm="7">
                                          <small className="text-muted font-weight-bold">
                                            {`${index + 1}: `}
                                          </small>
                                          <small className="font-weight-bold">
                                            {`${it.screenStarts[index]} - ${it.screenEnds[index]}`}
                                          </small>
                                        </Col>

                                        <Col sm="1">
                                          <a
                                            key={`screen-tooltip-${it.id}-${index}`}
                                            id={`screen-tooltip-${it.id}-${index}`}
                                            className="table-action table-action ml-2"
                                            href={file.url}
                                            download=""
                                          >
                                            <i className="fas fa-desktop" />
                                          </a>
                                        </Col>
                                        <Col sm="2">
                                          <Badge
                                            color={stateColor(file?.state)}
                                          >
                                            {file?.state}
                                          </Badge>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </Row>

                                <hr />
                              </>
                            );
                          })}

                        {take.assets.length > 0 && (
                          <>
                            <Row
                              key={`assets-${take.take}`}
                              className="align-items-center"
                            >
                              <Col sm="7">
                                <small className="text-uppercase text-muted font-weight-bold">
                                  Assets
                                </small>
                              </Col>

                              <Col sm="1">
                                <a
                                  className="table-action table-action ml-2"
                                  href={take.assets}
                                  id={`assets-${take.take}`}
                                  download=""
                                >
                                  <i className="far fa-file-powerpoint"></i>
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`assets-${take.take}`}
                                >
                                  Download assets
                                </UncontrolledTooltip>
                              </Col>
                            </Row>

                            <hr />
                          </>
                        )}

                        <Row className="align-items-center">
                          <Col sm="3">
                            <small className="text-uppercase text-muted font-weight-bold">
                              Notes
                            </small>
                          </Col>

                          <Col sm="9">
                            <small>
                              {take.notes.length === 0
                                ? "No notes"
                                : take.notes}
                            </small>
                          </Col>
                        </Row>

                        {take.error && (
                          <>
                            <hr />

                            <Row className="align-items-center">
                              <Col sm="3">
                                <small className="text-uppercase text-muted font-weight-bold">
                                  Errors
                                </small>
                              </Col>

                              <Col sm="9">
                                <small className="text-danger">
                                  {take.error}
                                </small>
                              </Col>
                            </Row>
                          </>
                        )}

                        <hr />
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              );
            })}

          {loading && <Loading />}
        </Row>
      </Container>
    </>
  );
}

export default GroupRecording;
