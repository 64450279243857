import React, { useState, useEffect, useContext, createContext } from 'react';
import * as Sentry from '@sentry/react';
import Loading from 'components/Loading/Loading';
import {
  listenForAuthUpdates,
  logout,
} from "@michaelprichardson/cloudpresent-core";

const AuthContext = createContext({ currentUser: null })

export const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(null)
  const [currentRole, setCurrentRole] = useState(0)
  const [isAdmin, setAdmin] = useState(false)
  const [isEditor, setEditor] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    listenForAuthUpdates(async (user) => {
      setCurrentUser(user);

      if (user) {
        const { claims } = await user.getIdTokenResult();
        const accessLevel = claims.accessLevel || 0;
        const isAdmin = claims.admin || false;
        const isEditor = claims.editor || false;

        if (!isAdmin) await logout();

        setCurrentRole(accessLevel);
        setAdmin(isAdmin);
        setEditor(isEditor);

        Sentry.setUser({ email: user.email });
      }

      setLoading(false);
    })
  }, [])

  if(loading){
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, currentRole, isAdmin, isEditor }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)