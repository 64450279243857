import _ from "lodash";
import { useState, useEffect } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Modal,
} from "reactstrap";
import { updateGroupRecordingHost } from '@michaelprichardson/cloudpresent-core';
import { validateEmail } from "utils/validation";

function EditGroupRecordingModal({ groupRecording, showModal, setShowModal, onGroupRecordingUpdated }) {
  const [hostName, setHostName] = useState('');
  const [hostEmail, setHostEmail] = useState('');
  const [enableEdit, setEnableEdit] = useState(false);

  useEffect(() => {
    setHostName(groupRecording.host.name);
    setHostEmail(groupRecording.host.email);
  }, [groupRecording])

  useEffect(() => {
    setEnableEdit(hostName.length !== 0 && validateEmail(hostEmail));
  }, [hostName, hostEmail])

  const onHostNameChanged = (event) => {
    setHostName(event.target.value);
  }

  const onHostEmailChanged = (event) => {
    setHostEmail(event.target.value);
  }
  const updateGroupRecordingClicked = async () => {
    setEnableEdit(false);
    await updateGroupRecordingHost(groupRecording.id, hostName, hostEmail);
    onGroupRecordingUpdated();
    setHostName('');
    setHostEmail('');
    setShowModal(false);
    setEnableEdit(true);
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        backdrop={false}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Event
          </h6>
        </div>
        <div className="modal-body">
          <Form autoComplete="off">
            <FormGroup>

              <label
                className="form-control-label"
                htmlFor="hostNameInout"
              >
                Host Name:
              </label>
              <Input
                id="hostNameInout"
                placeholder="Host name"
                type="text"
                value={hostName}
                onChange={e => onHostNameChanged(e)}
              />

              <label
                className="form-control-label"
                htmlFor="hostEmailInput"
              >
                Host Email:
              </label>
              <Input
                id="hostEmailInput"
                placeholder="Host email address"
                type="email"
                value={hostEmail}
                onChange={e => onHostEmailChanged(e)}
              />

            </FormGroup>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            disabled={!enableEdit}
            onClick={updateGroupRecordingClicked}
          >
            Update
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default EditGroupRecordingModal;
