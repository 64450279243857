import { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import {
  fetchGroupRecording,
  fetchGroupRecordingSessions,
} from "@michaelprichardson/cloudpresent-core";
import moment from "moment";
import clsx from "clsx";
import EditGroupRecordingModal from "components/EditGroupRecordingModal/EditGroupRecordingModal";

function GroupRecordingSessions({ match }) {
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [group, setGroup] = useState();
  const [groupSessions, setGroupSessions] = useState([]);
  const [numGroupSessions, setNumGroupSessions] = useState(0);

  const [showEditGroupRecordingDialog, setShowEditGroupRecordingDialog] =
    useState(false);

  useEffect(() => {
    const getRecordings = async () => {
      setLoading(true);

      const id = match.params.groupId;
      setGroupId(id);
      const groupDoc = await fetchGroupRecording(id);
      setGroup(groupDoc);

      const groupSessionDocs = await fetchGroupRecordingSessions(id);
      setGroupSessions(groupSessionDocs);
      setNumGroupSessions(groupSessionDocs.length);

      setLoading(false);
    };

    getRecordings();
  }, [match]);

  let recordingLink;
  if (group) {
    recordingLink = `${process.env.REACT_APP_PLATFORM_URL}/group-record${group.url}`;
  }

  const onGroupRecordingUpdated = async () => {
    setLoading(true);
    const groupDoc = await fetchGroupRecording(groupId);
    setGroup(groupDoc);
    setLoading(false);
  };

  return (
    <>
      <AlternativeHeader
        name={`${group?.topic} Sessions`}
        buttonTitle="Edit"
        buttonClicked={() => setShowEditGroupRecordingDialog(true)}
      />

      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Details</h3>
                </CardHeader>
                <CardBody>
                  {!loading && group && (
                    <>
                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Topic
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{group?.topic}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            URL
                          </small>
                        </Col>
                        <Col sm="8">
                          <small>
                            <a
                              href={recordingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {recordingLink}
                            </a>
                          </small>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Host
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{`${group.host.name} (${group.host.email})`}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Total Sessions
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">{numGroupSessions}</h5>
                        </Col>
                      </Row>

                      <Row className="py-3 align-items-center">
                        <Col sm="4">
                          <small className="text-uppercase text-muted font-weight-bold">
                            Created
                          </small>
                        </Col>
                        <Col sm="8">
                          <h5 className="mb-0">
                            {moment
                              .unix(group?.created.seconds || 0)
                              .format("HH:mm DD/MM/YYYY")}
                          </h5>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <Row></Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Participants</th>
                    <th>Status</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody className="list">
                  {groupSessions.map((session) => {
                    return (
                      <tr key={`group-recording-session-${session.id}`}>
                        <td>
                          <a
                            className="font-weight-bold"
                            href={`/dashboard/group-recording/${session.id}`}
                          >
                            {session.id}
                          </a>
                        </td>
                        <td>{session.participants.length}</td>
                        <td>
                          <Badge className="badge-dot mr-4">
                            <i
                              className={clsx({
                                "bg-danger": session.state === "pending",
                                "bg-warning": session.state === "editing",
                                "bg-success": session.state === "complete",
                                "bg-secondary": session.state === "ignored",
                              })}
                            />
                            <span className="status">{session.state}</span>
                          </Badge>
                        </td>
                        <td>
                          <span className="text-muted">
                            {moment
                              .unix(session.created.seconds || 0)
                              .format("HH:mm DD/MM/YYYY")}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>

      {group && (
        <EditGroupRecordingModal
          groupRecording={group}
          showModal={showEditGroupRecordingDialog}
          setShowModal={setShowEditGroupRecordingDialog}
          onGroupRecordingUpdated={onGroupRecordingUpdated}
        />
      )}
    </>
  );
}

export default GroupRecordingSessions;
